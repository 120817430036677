.center-image {
  display: block;
  margin: auto;
}

.image-container {
  text-align: center;
  margin-top: 40px;
}
.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
  padding-top: 100px; /* 검색 결과의 상단 패딩을 추가하여 간격을 늘림 */
}

.card {
  margin-top: 20px; /* Card를 위로 이동하기 위해 값을 조정 */
  padding: 20px; /* Card를 더 크게 만들기 위해 패딩을 늘립니다. */
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 600px; /* Card의 최대 너비를 지정합니다. */
  gap:20px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-container button {
  margin: 0 5px; /* 버튼 사이의 간격 조절 */
}